import {
  ChevronDown,
  ChevronRight,
  Menu,
  Puzzle,
  X,
  Grip,
  Rocket,
  Settings,
  HelpCircle,
  FileCheck,
  LogOut,
  LogIn,
  ArrowLeft,
  ArrowRight,
  AlertCircle,
  CheckCircle2,
  Bitcoin,
  CircleUserRound,
  Trash2,
  GlobeIcon,
  Vault,
  ChartPie,
  CalendarClock,
} from 'lucide-react';

import * as customIcons from './custom';

export const ICON = {
  X,
  Menu,
  FileCheck,
  Grip,
  LogIn,
  Puzzle,
  Rocket,
  LogOut,
  Trash2,
  Bitcoin,
  Settings,
  ArrowLeft,
  ArrowRight,
  HelpCircle,
  ChevronDown,
  ChevronRight,
  AlertCircle,
  CheckCircle2,
  CircleUserRound,
  GlobeIcon,
  Vault,
  ChartPie,
  CalendarClock,
  ...customIcons,
};
