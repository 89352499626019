export const INTERNAL_LINK = {
  SUPERPOWERS: '/#apps',
  EXTENSION: '/#extension',
  CREATORS: '/#creators',
  PREDICTION_MARKETS: '/#prediction-markets',
  TOKEN: '/#token',
};

export const EXTERNAL_LINK = {
  VAULT: '/vault',
};
